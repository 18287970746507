* {
  padding: 0;
  margin: 0;
}

.unAuth {
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #212121;
}

.intro {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding-top: 5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  color: #bdbdbd;
  border-bottom: 1px solid #757575;
}

.intro > p {
  font-size: large;
}

.intro > h3 {
  font-size: x-large;
}

.app {
  width: 100vw;
  min-height: 100vh;
  background-color: #212121;
}

button {
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  font-size: medium;
  border: none;
  outline: none;
}

.connectButton {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  background-color: #acacac;
  font-weight: 600;
  border-radius: 40px;
}

.connectButton > img {
  width: 2rem;
  filter: drop-shadow(0px 0px 2px);
}
.waveButtonParent {
  margin-top: 2rem;
  width: 100vw;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: center;
}
.waveButton {
  width: fit-content;
  font-weight: 500;
  color: white;
  background-color: #eb8039;
  border-radius: 50px;
}
.waveButtonParent > input {
  color: white;
  padding: 0.75rem;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: #2f2f2f;
  width: 15rem;
}

.waveList {
  border-radius: 15px;
  margin: 2rem;
  margin-bottom: 0;
  max-height: 17rem;
  background-color: #bdbdbd;
  overflow-y: scroll;
}

.waveCard {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  position: relative;
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
  background-color: #757575;
}

.wave__address {
  font-size: small;
  font-weight: 600;
}

.wave__time {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: small;
}

.wave__message {
  font-size: larger;
}

.loader {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
  background-color: #212121;
  opacity: 90%;
}

.loader__inner,
.loader__inner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader__inner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #eb803950;
  border-right: 1.1em solid #eb803950;
  border-bottom: 1.1em solid #eb803950;
  border-left: 1.1em solid #eb8039;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
